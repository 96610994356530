import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import styles from './Dashboard.module.css';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

import { Button } from '@mui/material';

import { fetchFromServer, dateStr, debug } from '../helpers/Helper';
import { softLoginCheck, getUserInfo } from '../helpers/LoginCheck';

import { update } from 'jdenticon';
import Loading from '../components/Loading';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Dashboard = () => {
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [dashboardInfo, setDashboardInfo] = useState({});
    // Use fetchFromServer to get the user's info




    useEffect(() => {
        const fetchData = async () => {
            
            try {
                const res = await fetchFromServer('get/dashboard_info', 'GET');
                debug('🟢 Dashboard Info:', res);
                if (! await res.ok) {
                    alert('🔴 Error fetching dashboard info:', res);
                    return;
                }
                setDashboardInfo(res);
            } catch (error) {
                alert('Error fetching dashboard info:', error);
            }
            
        };
        setLoading(true);
        fetchData();
        setLoading(false);
    }, []);


    const fetchDashboardInfo = async () => {
        setLoading(true);
        try {
            const res = await fetch('http://localhost:80/get/dashboard_info', {
                credentials: 'include', headers: {

                    'Content-Type': 'application/json'
                }
            });
            //const res = await fetchFromServer('get/dashboard_info', 'GET');
            debug('🟢 Dashboard Info:', res)
            if (! await res.ok) {
                alert('🔴 Error fetching dashboard info:', res)
                return;
            }
            setDashboardInfo(res);
        } catch (error) {
            alert('Error fetching dashboard info:', error);
        }
        setLoading(false);
    };

    function removeCookie() {
        try {
            cookies.remove('authorization');
            window.location.reload();
        } catch (error) {
            debug("cookie not removed", error);
        };
    }


    const cookies = new Cookies();
    const svgRef = useRef(null);

    // Generate the identicon with the user's email as a hash
    useEffect(() => {
        if (svgRef.current && getUserInfo().email) {
            update(svgRef.current, getUserInfo().email);
        }
        console.log('User info:', getUserInfo());
    }, [userInfo]);

    return (
        <React.StrictMode>
            <Banner />
            {loading ? <Loading /> : null}
            <div className={styles['content-wrapper']}>
                <div className={styles['dash-wrapper']}>
                    <div className={styles['background-shape']}>
                        <svg width="auto" height="auto" viewBox="25 25 175 175" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="grad1" x1="30%" y1="30%" x2="70%" y2="70%">
                                    <stop offset="0%" style={{ stopColor: 'var(--palette-red-1)', stopOpacity: 1 }} />
                                    <stop offset="100%" style={{ stopColor: 'var(--palette-blue-1)', stopOpacity: 1 }} />
                                </linearGradient>
                            </defs>
                            <path fill="url(#grad1)" d="M57.3,-19.8C62.3,-3.2,46.2,19.2,24.4,35.5C2.5,51.9,-25.1,62.3,-44.4,50.7C-63.8,39.1,-74.8,5.6,-66,-16.2C-57.2,-38.1,-28.6,-48.3,-1.2,-47.9C26.1,-47.5,52.3,-36.5,57.3,-19.8Z" transform="translate(100 100)" />
                        </svg>
                    </div>
                    <div className={styles['field-wrapper']}>
                        <section style={{ display: 'flex', flexDirection: 'row', padding: '0' }}>
                            <div className={styles['identicon-wrapper']}>
                                <svg width="auto" height="auto" ref={svgRef} data-jdenticon-value={getUserInfo().email}></svg>
                            </div>
                            <section>
                                <h2>{getUserInfo().name}</h2>
                                <h3>{getUserInfo().email}</h3>
                                <h4>
                                    <bold>{dashboardInfo.tierInfo?.name}</bold> Tier {dashboardInfo.subscription?.price !== 0 && (
                                        <> ⋅ Renews {dashboardInfo.subscription?.renewDate
                                            ? new Date(dashboardInfo.subscription?.renewDate).toLocaleDateString(undefined, {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            })
                                            : ''} @ {dashboardInfo.subscription?.price ? `$${dashboardInfo.subscription?.price}` : '$0.00'}
                                        </>
                                    )}
                                </h4>
                                <h4>{dashboardInfo.subscription?.termCount}/{dashboardInfo.tierInfo?.termLimit} Generations Left This Week.</h4>
                            </section>
                        </section>
                        <section style={{ padding: '.5rem' }}>
                            <Button className={'button'} onClick={removeCookie}><red>Sign Out</red></Button>
                        </section>
                    </div>

                    <div style={{ paddingBottom: '1rem' }}>
                        <h4>* Dashboard info may not be completely indicative of actual data.</h4>
                    </div>

                    <div
                        style={{ flexDirection: 'column' }}
                        className={`${styles['datafield-container']} ${styles['dashboard-wrapper']}`}
                    >

                        <div className={`${styles['datafield-wrapper']} ${styles['dashboard-wrapper']}`}>
                            {dashboardInfo.pastRequests?.map((request, index) => (
                                <div key={index} className={styles['request-wrapper']}>
                                    <h3>
                                        <bold>
                                            {request.metadata?.GEN_DATA?.JOB_DATA?.TITLE ? request.metadata?.GEN_DATA?.JOB_DATA?.TITLE : 'Unknown Job'} @{' '}
                                            {request.metadata?.GEN_DATA?.JOB_DATA?.COMPANY ? request.metadata?.GEN_DATA?.JOB_DATA?.COMPANY : 'Unknown Company'}
                                        </bold>
                                    </h3>
                                    <h4>{' '}
                                        {new Date(request.createdAt).toLocaleString(undefined, {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit'
                                        })}
                                    </h4>
                                </div>
                            ))}
                        </div>
                        <h4>Recent Applications</h4>
                    </div>

                </div>
            </div>
            <Footer />
        </React.StrictMode>
    );
}

export default Dashboard;
