import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import styles from './CovrAI.module.css';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { fetchFromServer } from '../helpers/Helper';

import UserInfoForm from '../components/UserInfoForm';
import JobInfoForm from '../components/JobInfoForm';
import Loading from '../components/Loading';

import { dateStr, debug } from '../helpers/Helper.js'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function CovrAI() {
  const [loading, setLoading] = useState(false);

  const cookies = new Cookies();

  const [userFormData, setUserFormData] = useState(() => {
    const savedData = cookies.get('userFormData') ? cookies.get('userFormData') : {
      name: '',
      email: '',
      title: '',
      phone: '',
      education: '',
      study: '',
      location: '',
      createAccount: true,
      password: '',
      skills: [],
      experiences: []
    };

    // Get the resume from LocalStorage if available
    let storedResume = '';
    try {
      storedResume = JSON.parse(localStorage.getItem('userResume')).text;
    } catch (error) {
      console.error('Failed to get resume from LocalStorage:', error);
    }
    // Return the merged object, prioritizing LocalStorage for the resume
    return {
      ...savedData,
      resume: storedResume
    };
  });

  useEffect(() => {
    console.log('User form data:', userFormData);
    // Create a filtered version of userFormData excluding 'resume', 'password', and 'createAccount'
    const { resume, ...filteredData } = userFormData;

    // Save the filtered data to the cookie
    const updatedData = {
      ...filteredData,
      resume: ''  // Set resume to an empty string if it's undefined
    };
    cookies.set('userFormData', updatedData, { path: '/', maxAge: 3200000 });

    // Save resume to LocalStorage
    // if (resume) {
    //   const storedResume = localStorage.getItem('userResume');
    //   const newResumeData = JSON.stringify({ text: resume, date: new Date().toISOString() });
    //   if (!storedResume || JSON.parse(storedResume).text !== resume) {
    //     localStorage.setItem('userResume', newResumeData);
    //   }
    // } else {
    //   // Optionally remove the resume if it's empty
    //   localStorage.removeItem('userResume');
    // }
  }, [userFormData]);

  const [jobFormData, setJobFormData] = useState(
    cookies.get('jobFormData') ? cookies.get('jobFormData') : {
      title: '',
      company: '',
      description: '',
      autoTone: true,
      desiredTone: 0,
      autoSkills: true,
      desiredSkills: [],
      autoExperience: true,
      desiredExperience: '',
      desiredTemplate: ''
    });

  useEffect(() => {
    cookies.set('jobFormData', jobFormData, { path: '/', maxAge: 3200000 });
  }, [jobFormData]);

  const [formHighlight, setFormHighlight] = useState({
    user_name: false,
    user_email: false,
    user_resume: false,
    job_title: false,
    job_company: false,
    job_description: false
  });

  const query = useQuery();
  const [showFirst, setShowFirst] = useState(query.get('form') !== 'job');

  const sendGenerationRequest = async () => {
    const tempFormData = {
      user_name: userFormData.name === '' ? true : false,
      user_email: userFormData.email === '' ? true : false,
      user_resume: userFormData.resume === '' ? true : false,
      job_title: jobFormData.title === '' ? true : false,
      job_company: jobFormData.company === '' ? true : false,
      job_description: jobFormData.description === '' ? true : false
    };

    // Update formHighlight state using functional update
    setFormHighlight((prevState) => ({ ...prevState, ...tempFormData }));

    // If any required fields are empty, do not send the request
    if (userFormData.resume === '') {
      setShowFirst(true);
      setTimeout(() => {
        alert('Drag and drop your resume file to the box below to upload it.');
      }, 5);
      return;
    }

    if (userFormData.name === '' || userFormData.email === '' || userFormData.resume === '' || jobFormData.title === '' || jobFormData.company === '' || jobFormData.description === '') {
      alert('Please fill out all required fields.');
      return;
    }

    setLoading(true);
    try {
      const jsonPayload = {
        DATA: {
          USER: {
            NAME: userFormData.name,
            TITLE: userFormData.title,
            EMAIL: userFormData.email,
            PHONE: userFormData.phone,
            LOCATION: userFormData.location,
            EDUCATION: userFormData.education,
            STUDY: userFormData.study,
            RESUME: userFormData.resume
          },
          JOB: {
            TITLE: jobFormData.title,
            COMPANY: jobFormData.company,
            DESCRIPTION: jobFormData.description
          },
          CONFIG: {
            TEMPLATE: jobFormData.desiredTemplate,
            TONE: jobFormData.autoTone ? 0 : jobFormData.desiredTone,
            SKILLS: jobFormData.autoSkills ? [] : jobFormData.desiredSkills,
            EXPERIENCES: jobFormData.autoExperience ? [] : [jobFormData.desiredExperience],
            DATE: dateStr()
          }
        }
      }

      // Save resume to LocalStorage
      const storedResume = localStorage.getItem('userResume');
      const newResumeData = JSON.stringify({ text: userFormData.resume, date: new Date().toISOString() });
      if (!storedResume || JSON.parse(storedResume).text !== userFormData.resume) {
        localStorage.setItem('userResume', newResumeData);
        debug('Saved resume to LocalStorage:', newResumeData);
      }

      console.log('Sending payload:', jsonPayload);
      /*
      const cookie = new Cookies();
      const authorization = cookie.get('authorization');

     
      const res = await fetch('http://localhost:80/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${authorization}`
        },
        body: JSON.stringify(jsonPayload)
      });

      if (!res.ok) {
        alert('Problem with processing your request, please try again later.');
        console.error('Failed to send generation request:', res);
        setLoading(false);
        return;
      }

      const data = await res.json();
*/
      const data = await fetchFromServer(
        'api/generate',
        'POST',
        JSON.stringify(jsonPayload));

      if (!await data.ok) {
        alert('Problem with processing your request, please try again later.');
        debug('Failed to send generation request:', data);
        setLoading(false);
        return;
      }

      console.log('Response:', data);


      for (var key in data['DATA']) {
        var filename = data['DATA'][key]['FILE_NAME'] + "." + data['DATA'][key]['FILE']['ext'];
        var binary = atob(data['DATA'][key]['FILE']['base64']);
        var byte = new Uint8Array(binary.length);
        for (let i = 0; i < byte.length; i++) {
          byte[i] = binary.charCodeAt(i);
        }
        if (data['DATA'][key]['FILE']['ext'] == 'pdf') {
          var blob = new Blob([byte], { type: 'application/pdf' });
        } else {
          var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        };

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        // Clean up
        a.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error sending generation request:', error);
    }
    setLoading(false);
  };

  const toggleForm = () => {
    setShowFirst(!showFirst);
    if (showFirst) {
      window.history.pushState(null, '', '?form=job')
    } else {
      window.history.pushState(null, '', '?form=user')
    }
  }

  return (
    <React.StrictMode>
      {loading && <Loading />}
      <Banner />
      <div className={styles['content-wrapper']}>
        <div></div>
        <div className={`${styles['form-wrapper']} ${showFirst ? styles['fade-in'] : styles['fade-out']}`}>
          <UserInfoForm changeForm={toggleForm} formData={userFormData} changeFormData={setUserFormData} fieldStatus={formHighlight} changeFieldStatus={setFormHighlight} />
        </div>

        <div className={`${styles['form-wrapper']} ${showFirst ? styles['fade-out'] : styles['fade-in']}`}>
          <JobInfoForm changeForm={toggleForm} onFormSubmit={sendGenerationRequest} formData={jobFormData} changeFormData={setJobFormData} userInfo={userFormData} />
        </div>
        <div></div>
      </div>
      <Footer />
    </React.StrictMode>
  );
}

export default CovrAI;
