import Cookies from 'universal-cookie';
import { jwtDecode } from "jwt-decode";
import { fetchFromServer, dateStr, debug } from './Helper';

export function softLoginCheck() {
  const cookies = new Cookies();
  const token = cookies.get("authorization"); // Fetch the token from storage

  if (token === undefined) {
    debug('🔴 Soft Missing cookie, redirecting to login.');
    return false; // No token, not authenticated
  }

  return true;
}

export function getUserInfo() {
  try {
    debug('🟢 Decoding token.');
    const cookies = new Cookies();
    const token = cookies.get("authorization"); // Fetch the token from storage

    const info = jwtDecode(token);
    return info;
  } catch (error) {
    debug('🔴 Error decoding token:', error);
    return null;
  }
}